import type { I18n, IntlDateTimeFormats } from 'vue-i18n';
import { useI18n } from 'vue-i18n';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, fa } from 'vuetify/iconsets/fa';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';

import colors from 'vuetify/util/colors';
import 'vuetify/styles';
import '../sass/app.scss';

const commonColors = {
  'font': colors.grey.darken2,
  'unacknowleged': colors.grey.lighten3,
  'light-grey': colors.grey.lighten4,
  'dark-grey': colors.grey.darken1,
  'error': colors.red.base,
  'warning': colors.orange.darken1,
  'success': colors.green.base,
};

const sapphireTheme = {
  colors: {
    'primary': '#428ECC',
    'primary-darken-1': '#18499A',
    'primary-lighten-1': '#E3F2FD',
    'secondary': '#ff8811',
    ...commonColors,
  },
};

const purpleTheme = {
  colors: {
    'primary': '#7E57C2',
    'primary-darken-1': '#4527a0',
    'primary-lighten-1': '#EDE7F6',
    'secondary': '#ff8811',
    ...commonColors,
  },
};

const graphiteTheme = {
  colors: {
    'primary': '#717684',
    'primary-darken-1': '#44444c',
    'primary-lighten-1': '#eff0f1',
    'secondary': '#ff8811',
    ...commonColors,
  },
};

const emeraldTheme = {
  colors: {
    'primary': '#399381',
    'primary-darken-1': '#31574f',
    'primary-lighten-1': '#d3eee8',
    'secondary': '#ff8811',
    ...commonColors,
  },
};

const tealTheme = {
  colors: {
    'primary': '#02979D',
    'primary-darken-1': colors.cyan.darken4,
    'primary-lighten-1': '#ebfeff',
    'secondary': '#FF8811',
    ...commonColors,
  },
};

const rubyTheme = {
  colors: {
    'primary': '#781e4c',
    'primary-darken-1': '#46162e',
    'primary-lighten-1': '#f5e0ea',
    'secondary': '#ff8811',
    ...commonColors,
  },
};

const blueTheme = {
  colors: {
    'primary': '#1c5c9c',
    'primary-darken-1': '#103152',
    'primary-lighten-1': '#DCEAF9',
    'secondary': '#ff8811',
    ...commonColors,
  },
};

const sodaliteTheme = {
  colors: {
    'primary': '#327d9a',
    'primary-darken-1': '#225569',
    'primary-lighten-1': '#f0f7fa',
    'secondary': '#ff8811',
    ...commonColors,
  },
};

export function initVuetify(
  i18n: I18n<{}, IntlDateTimeFormats, {}, string, false>,
) {
  return createVuetify({
    locale: {
      adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
    defaults: {
      VAlert: { variant: 'tonal', icon: '' },
      VBtn: {
        size: 'small',
        class: 'text-no-transform',
        style: 'letter-spacing: 0',
      },
      VCard: {
        VCardActions: {
          VBtn: { variant: 'elevated' },
        },
      },
      VDataTable: { density: 'comfortable' },
      VBtnGroup: { density: 'compact' },
      VCheckbox: {
        density: 'compact',
        color: 'primary',
        style: 'height: 45px',
      },
      VCombobox: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
      },
      VDivider: { thickness: '2', color: 'grey-lighten-1' },
      VTextField: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
      },
      VTextarea: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
      },
      VFileInput: {
        variant: 'outlined',
        density: 'compact',
        color: 'primary',
      },
      VRadioGroup: { density: 'compact', color: 'primary' },
      VRadio: { density: 'compact', color: 'primary' },
      VNavBar: { touchless: true },
      VAutocomplete: { variant: 'outlined', density: 'compact' },
      VPagination: { color: 'primary', density: 'compact' },
      VSelect: { variant: 'outlined', density: 'compact' },
      VSwitch: {
        color: 'primary',
        density: 'compact',
        style: 'height: 35px',
      },
      VTabs: {
        'grow': true,
        'class': 'global-tabs border-b-md mb-4',
        'show-arrows': true,
      },
    },
    components,
    directives,
    icons: {
      defaultSet: 'fa',
      aliases,
      sets: {
        fa,
      },
    },
    theme: {
      defaultTheme: 'tealTheme',
      themes: {
        sapphireTheme,
        tealTheme,
        graphiteTheme,
        purpleTheme,
        sodaliteTheme,
        blueTheme,
        rubyTheme,
        emeraldTheme,
        light: {
          dark: false,
        },
      },
    },
  });
}
