<template>
  <!-- if changing width, remember to update the footer too -->
  <v-navigation-drawer
    v-model="isMenuOpen"
    v-model:rail="isRailMode"
    color="primary"
    width="256"
  >
    <template
      v-if="!mobile && !isRailMode"
      #prepend
    >
      <router-link
        to="/"
      >
        <div class="bg-primary-darken-1 pa-4">
          <v-img
            class="pa-3"
            aspect-ratio="1.7"
            src="/storage/logo/logo_light.svg"
          />
        </div>
      </router-link>
    </template>
    <v-list density="compact">
      <v-list-item
        v-if="!isRailMode && !mobile"
        class="text-center text-caption"
      >
        <i18n-t
          keypath="global.welcome_text"
        >
          <template #user>
            <b>{{ authUser.full_name }}</b>
          </template>
          <template #city>
            {{ getConfig('smpw.city_name') }}
          </template>
        </i18n-t>
      </v-list-item>
      <menu-item
        to="/"
        :title="t('global.home')"
        icon="fas fa-home"
      />
      <menu-item
        v-if="authUser.is_admin"
        to="/admin"
        :title="t('global.admin')"
        icon="fas fa-key"
      />
      <menu-item
        v-if="can('access-news-page')"
        to="/news/current"
        :title="t('news.title', 2)"
        icon="fas fa-newspaper"
        :pill="unreadNewsCount"
      />
      <v-divider
        :thickness="2"
        color="white"
        class="my-2"
      />
      <menu-item
        to="/myshifts"
        :title="t('shift.mine')"
        icon="far fa-clock"
        class="menu_badge_holder"
        :pill="unacknowledgedShiftsCount"
      />
      <menu-item
        v-if="can('submit-availability')"
        to="/mycalendar"
        :title="t('calendar.mine')"
        icon="fas fa-calendar"
      />
      <menu-item
        v-if="loadingCount === 0 && teamCount > 0"
        to="/myteams"
        :title="t('team.mine', teamCount)"
        icon="fas fa-sitemap"
      />
      <menu-item
        to="/mydetails"
        :title="t('global.my_details')"
        icon="fas fa-user"
      />
      <menu-item
        v-if="can('handle-tasks') || can('manage-tasks')"
        to="/tasks"
        :title="t('task.mine')"
        icon="fas fa-clipboard-check"
        class="menu_badge_holder"
        :pill="newAssignedTasksCount"
      />
      <menu-item
        v-if="can('submit-availability')"
        to="/currentneeds"
        :title="t('shift.current_needs')"
        icon="fa-solid fa-users-line"
      />
      <menu-item
        v-if="can('access-cart-page')"
        to="/carts"
        :title="t('cart.current')"
        icon="fa-solid fa-suitcase-rolling"
      />
      <menu-item
        v-if="can('access-dayoverseers-page')"
        to="/dayoverseers"
        :title="t('dayoverseer.title', 2)"
        icon="fas fa-people-group"
      />
      <menu-item
        v-if="can('access-locations-page')"
        to="/locations"
        :title="t('location.title', 2)"
        icon="fas fa-map-marker-alt"
      />
      <menu-item
        v-if="can('access-faqs-page')"
        to="/faqs"
        :title="t('faq.title', 2)"
        icon="fas fa-circle-question"
      />
      <v-divider
        :thickness="2"
        color="white"
        class="my-2"
      />
      <menu-item
        v-if="can('access-experiences-page')"
        to="/experiences"
        :title="t('experience.title', 2)"
        icon="fas fa-comments"
      />
      <menu-item
        v-if="can('access-statistics-page')"
        to="/statistics"
        :title="t('global.statistics')"
        icon="far fa-chart-bar"
      />
      <menu-item
        v-if="can('submit-contact-message')"
        to="/contacts/submit"
        :title="t('actions.contact_us')"
        icon="fas fa-envelope"
      />
      <v-divider
        v-if="
          can('access-experiences-page')
            || can('submit-experience')
            || can('access-statistics-page')
            || can('submit-contact-message')"
        :thickness="2"
        color="white"
        class="my-2"
      />

      <v-list-group
        value="help"
      >
        <template #activator="{ props }">
          <v-list-item
            :title="t('global.help')"
            min-height="35px"
            v-bind="props"
          >
            <template #prepend>
              <v-icon
                class="me-n4"
                icon="fas fa-circle-question"
                size="x-small"
              />
            </template>
          </v-list-item>
        </template>
        <help-menu />
      </v-list-group>
      <menu-item
        :title="t('login.log_out')"
        icon="fas fa-sign-out-alt"
        @click="logout()"
      />
      <v-divider
        :thickness="2"
        color="white"
        class="my-2"
      />
      <menu-item
        :title="t('global.collapse')"
        :icon="isRailMode ? 'fas fa-chevron-right' : 'fas fa-chevron-left'"
        @click="toggleVisibility"
      />
    </v-list>
  </v-navigation-drawer>
  <v-app-bar
    color="primary-darken-1"
    :height="mobile ? '100' : undefined"
    :elevation="0"
  >
    <v-row>
      <v-col class="d-flex justify-start align-center">
        <v-app-bar-nav-icon
          :size="smAndUp ? 'large' : 'small'"
          @click="toggleVisibility"
        />
      </v-col>
      <v-col
        v-if="mobile"
        class="d-flex justify-space-around align-center"
      >
        <v-app-bar-title>
          <router-link
            to="/"
          >
            <v-img
              height="80"
              position="center"
              aspect-ratio="2.5"
              src="/storage/logo/logo_light.svg"
            />
          </router-link>
        </v-app-bar-title>
      </v-col>
      <v-col class="d-flex justify-end align-center">
        <v-btn
          v-if="canAny(['manage-shifts', 'view-all-shifts'])"
          to="/admin/shifts/schedule"
          data-testid="nav-shift-management"
          class="me-1"
          icon="fas fa-calendar-alt"
          :size="smAndUp ? 'large' : 'x-small'"
        />
        <v-btn
          v-if="can('manage-users')"
          to="/admin/users"
          icon="fas fa-user-friends"
          class="me-1"
          :size="smAndUp ? 'large' : 'x-small'"
        />
        <v-btn
          v-if="authUser.is_admin"
          to="/admin"
          icon="fas fa-key"
          :size="smAndUp ? 'large' : 'x-small'"
        />
      </v-col>
    </v-row>
  </v-app-bar>
  <v-app-bar
    v-if="mobile"
    color="primary"
    density="compact"
    :elevation="2"
  >
    <v-app-bar-title class="text-caption text-center">
      <i18n-t
        keypath="global.welcome_text_short"
      >
        <template #user>
          <b>{{ authUser.full_name }}</b>
        </template>
        <template #city>
          {{ getConfig('smpw.city_name') }}
        </template>
        <template #acronym>
          {{ getConfig('smpw.acronym') }}
        </template>
      </i18n-t>
    </v-app-bar-title>
  </v-app-bar>
</template>

<script setup lang="ts">
import {
  useAuth,
  useConfig,
  useMenu,
  usePermissions,
} from '@/composables';
import { useMenuStore } from '@/stores/menu';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import {
  onMounted,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import HelpMenu from './menu/HelpMenu.vue';
import MenuItem from './menu/MenuItem.vue';

const { t } = useI18n();
const { mobile, smAndUp } = useDisplay();
const {
  isMenuOpen,
  isRailMode,
  setMenuClosed,
  setMenuOpen,
  toggleMenuOpen,
  setRailModeOff,
  toggleRailMode,
} = useMenu();

const { getConfig } = useConfig();

const { can, canAny } = usePermissions();
const { authUser, logout } = useAuth();

const menuStore = useMenuStore();
const { unreadNewsCount, newAssignedTasksCount, unacknowledgedShiftsCount, teamCount } = storeToRefs(menuStore);

const loadingCount = ref(0);

function toggleVisibility() {
  if (mobile.value) {
    toggleMenuOpen();
    setRailModeOff();
  }
  else {
    toggleRailMode();
    setMenuOpen();
  }
}

onMounted(() => {
  setRailModeOff();
  if (mobile.value) {
    setMenuClosed();
  }
  else {
    setMenuOpen();
  }
});

async function loadMainMenuData() {
  loadingCount.value += 1;
  try {
    const response = await axios.get('/api/menu/main');
    unreadNewsCount.value = response.data.data.unreadNewsCount;
    newAssignedTasksCount.value = response.data.data.newAssignedTasksCount;
    unacknowledgedShiftsCount.value = response.data.data.unacknowledgedShiftsCount;
    teamCount.value = response.data.data.teamCount;
  }
  finally {
    loadingCount.value -= 1;
  }
}

loadMainMenuData();
</script>

<style lang="scss" scoped>
:deep(.v-navigation-drawer__content) {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(var(--v-theme-primary-lighten-1));
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(var(--v-theme-primary-darken-1));
  }
}
</style>
